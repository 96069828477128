import type { IdentityVaultConfig } from './IdentityVaultConfig';
import type { VaultInterface, VaultLockEvent } from './VaultInterface';
import type { VaultError } from './definitions';
import { VaultErrorCodes } from './definitions';

type Callback<T = any> = (value: T) => void;
type EmptyCallback = () => void;
const STORAGE_KEYS = {
  DATA: 'data',
};

/**
 * THIS VAULT DOES NOT IMPLEMENT SECURE STORAGE IN THE BROWSER. It only exists
 * as a way to run browser-compatible code in place of Identity Vault. Browsers
 * do not have a secure storage element same as native devices. This class
 * is intended to be used to enable running your application in the browser while
 * simulating the functions of Identity Vault using sessionStorage.
 *
 * Represents a vault implementation for browser compatibility.
 */
export class BrowserVault implements VaultInterface {
  /** @ignore */
  private isVaultLocked = false;

  /** @ignore */
  private isVaultEmpty = true;

  /** @ignore */
  private configCallback: Callback<IdentityVaultConfig> | undefined;

  /** @ignore */
  private errorCallback: Callback<VaultError> | undefined;

  /** @ignore */
  private lockCallback: Callback<VaultLockEvent> | undefined;

  /** @ignore */
  private unlockCallback: EmptyCallback | undefined;

  /** @ignore */
  config?: IdentityVaultConfig;

  /**
   * @usage
   * ```typescript
   * const vault = new Vault({
   *  key: 'com.company.myvaultapp',
   *  type: 'CustomPasscode',
   *  deviceSecurityType: 'Both',
   *  lockAfterBackgrounded: 2000,
   * });
   * ```
   * @param config
   */
  constructor(config?: IdentityVaultConfig) {
    console.warn(
      'THIS VAULT DOES NOT IMPLEMENT SECURE STORAGE IN THE BROWSER AND IS NOT INTENDED FOR PRODUCTION USE. It only exists as a way to run browser-compatible code in place of Identity Vault. Browsers do not have a secure storage element same as native devices. This class is intended to be used to enable running your application in the browser while simulating the functions of Identity Vault using sessionStorage.'
    );
    if (!config) return;
    this.config = Object.assign(
      {
        deviceSecurityType: 'Both',
        androidBiometricsPreferStrongVaultOrSystemPasscode: 'StrongVault',
        shouldClearVaultAfterTooManyFailedAttempts: false,
        customPasscodeInvalidUnlockAttempts: 5,
        unlockVaultOnLoad: false,
      },
      config
    );
    this.isVaultLocked = config.unlockVaultOnLoad ? false : true;
  }

  /** @deprecated Deprecated in favor of using the isEmpty method. */
  /** See {@link Vault.doesVaultExist} */
  doesVaultExist(): Promise<boolean> {
    const data = this.getDataObj();
    return Promise.resolve(!!data);
  }

  /**
   * @usage
   * ```typescript
   * vault.initialize({
   *  key: 'com.company.myvaultapp',
   *  type: 'CustomPasscode',
   *  deviceSecurityType: 'Both',
   *  lockAfterBackgrounded: 2000,
   * });
   * ```
   * @param config
   */
  initialize(config: IdentityVaultConfig): Promise<void> {
    this.config = Object.assign(
      {
        deviceSecurityType: 'Both',
        androidBiometricsPreferStrongVaultOrSystemPasscode: 'StrongVault',
        shouldClearVaultAfterTooManyFailedAttempts: false,
        customPasscodeInvalidUnlockAttempts: 5,
        unlockVaultOnLoad: false,
      },
      config
    );
    this.isVaultLocked = config.unlockVaultOnLoad ? false : true;
    return Promise.resolve();
  }

  /** See {@link Vault.clear} */
  clear(): Promise<void> {
    this.unlockIfLocked();
    sessionStorage.removeItem(this.getKey(STORAGE_KEYS.DATA));
    this.isVaultEmpty = true;
    return Promise.resolve();
  }

  /** See {@link Vault.exportVault} */
  exportVault(): Promise<Record<string, string>> {
    this.unlockIfLocked();
    const data = this.getDataObj();
    return Promise.resolve(data ?? {});
  }

  /** See {@link Vault.importVault} */
  importVault(data: Record<string, string>): Promise<void> {
    this.setDataObj(data);
    return Promise.resolve();
  }

  /** See {@link Vault.isLocked} */
  isLocked(): Promise<boolean> {
    return Promise.resolve(this.isVaultLocked);
  }

  /** See {@link Vault.isEmpty} */
  isEmpty(): Promise<boolean> {
    return Promise.resolve(this.isVaultEmpty);
  }

  /** See {@link Vault.getKeys} */
  getKeys(): Promise<string[]> {
    this.unlockIfLocked();
    const data = this.getDataObj();
    if (!data) return Promise.resolve([]);
    return Promise.resolve(Object.keys(data));
  }

  /** See {@link Vault.getValue} */
  getValue<T = any>(key: string): Promise<T | null> {
    this.unlockIfLocked();
    const data = this.getDataObj();
    return Promise.resolve(data?.[key] ?? null);
  }

  /** See {@link Vault.lock} */
  lock(): Promise<void> {
    this.isVaultLocked = true;
    this.lockCallback?.({ timeout: false });
    return Promise.resolve();
  }

  /** See {@link Vault.removeValue} */
  removeValue(key: string): Promise<void> {
    this.unlockIfLocked();
    const data = this.getDataObj();
    if (!data) return Promise.resolve();
    const { [key]: removed, ...dataAfterRemoval } = data;
    this.setDataObj(dataAfterRemoval);
    return Promise.resolve();
  }

  /** See {@link Vault.setCustomPasscode} */
  setCustomPasscode(passcode: string): Promise<void> {
    return Promise.resolve();
  }

  /** See {@link Vault.setValue} */
  setValue<T = any>(key: string, value: T): Promise<void> {
    this.unlockIfLocked();
    const data = this.getDataObj();
    if (!data) {
      this.setDataObj({ [key]: value });
    } else {
      this.setDataObj({ ...data, [key]: value });
    }
    return Promise.resolve();
  }

  /** See {@link Vault.onConfigChanged} */
  onConfigChanged(callback: Callback<IdentityVaultConfig>): void {
    this.configCallback = callback;
  }

  /** See {@link Vault.onError} */
  onError(callback: Callback<VaultError>): void {
    this.errorCallback = callback;
  }

  /** See {@link Vault.onLock} */
  onLock(callback: Callback<VaultLockEvent>): void {
    this.lockCallback = callback;
  }

  /** See {@link Vault.onPasscodeRequested} */
  onPasscodeRequested(callback: (isPasscodeSetRequest: boolean, onComplete: (code: string) => void) => void): void;
  onPasscodeRequested(callback: (isPasscodeSetRequest: boolean) => Promise<void>): void;
  onPasscodeRequested(callback: (isPasscodeSetRequest: boolean, onComplete: (code: string) => void) => void): void {
    // No passcode support
  }

  /** See {@link Vault.onUnlock} */
  onUnlock(callback: EmptyCallback): void {
    this.unlockCallback = callback;
  }

  /** See {@link Vault.unlock} */
  unlock(): Promise<void> {
    this.isVaultLocked = false;
    this.unlockCallback?.();
    return Promise.resolve();
  }

  /** See {@link Vault.updateConfig} */
  updateConfig(config: IdentityVaultConfig): Promise<void> {
    this.config = config;
    this.configCallback?.(config);
    return Promise.resolve();
  }

  /** @ignore */
  requestBiometricPrompt(): Promise<boolean> {
    this.unlockIfLocked();
    return Promise.resolve(true);
  }

  /** @ignore */
  private unlockIfLocked(): void {
    if (this.isVaultLocked) {
      this.unlock();
    }
  }

  /** @ignore */
  private getDataObj(): Record<string, any> | null {
    const value = sessionStorage.getItem(this.getKey(STORAGE_KEYS.DATA));
    if (!value) return null;
    try {
      const dataObj = JSON.parse(value);
      this.isVaultEmpty = Object.keys(dataObj).length <= 0;
      return dataObj;
    } catch (e) {
      this.errorCallback?.({
        message: 'Unable to parse data store',
        code: VaultErrorCodes.Unknown,
      });
      return null;
    }
  }

  /** @ignore */
  private setDataObj(data: Record<string, any>): void {
    try {
      const dataStr = JSON.stringify(data);
      sessionStorage.setItem(this.getKey(STORAGE_KEYS.DATA), dataStr);
      this.isVaultEmpty = Object.keys(data).length <= 0;
    } catch (e) {
      this.errorCallback?.({
        message: 'Unable to serialize data',
        code: VaultErrorCodes.Unknown,
      });
    }
  }

  /** @ignore */
  private getKey(key: string): string {
    return `IV-${this.config?.key}-${key}`;
  }
}
