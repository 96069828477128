/// <reference types="cordova" />

/**
 * Logging levels for the plugin.
 */
export enum LogLevel {
  None = 0,
  Error = 1,
  Warning = 2,
  Debug = 3,
}

export class IVLogger {
  /**
   * Sets the logging level for the plugin.
   * @param level The logging level to set.
   *
   * Default logging level is {@link LogLevel.None}.
   */
  static async setLoggingLevel(level: LogLevel): Promise<void> {
    return new Promise((resolve, _reject) => {
      cordova.exec(
        resolve,
        (error) => {
          console.error(`[IVLOGGER - ERROR] | ${error.message ?? 'Unknown error'}`);
        },
        'VaultPlugin',
        'IVLoggerSetLoggingLevel',
        [level]
      );
    });
  }
}

document.addEventListener('deviceready', () => {
  console.log(`[IVLogger] | Initializing...`);
  cordova.exec(
    (data: string) => {
      try {
        const parsedData: { message: string; logType: LogLevel } = JSON.parse(data);
        const message = parsedData.message;
        switch (parsedData.logType) {
          case LogLevel.Debug:
            console.log(`[IVLOGGER - DEBUG] | ${message}`);
            break;
          case LogLevel.Warning:
            console.warn(`[IVLOGGER - WARNING] | ${message}`);
            break;
          case LogLevel.Error:
            console.error(`[IVLOGGER - ERROR] | ${message}`);
            break;
        }
      } catch (error: any) {
        console.error(`[IVLOGGER - ERROR] | ${error.message ?? 'Unknown error'}`);
      }
    },
    (error) => {
      console.error(`[IVLOGGER - ERROR] | ${error.message ?? 'Unknown error'}`);
    },
    'VaultPlugin',
    'IVLoggerInit',
    []
  );
});
