import type { VaultDescriptor, VaultOptions } from './definitions';
import { IonicIdentityVaultUser } from './vault-user';

type onPasscodeRequestCallback = (_isPasscodeSetRequest: boolean) => Promise<string | undefined>;

class MigratorVault extends IonicIdentityVaultUser<any> {
  private static instance: MigratorVault | undefined = undefined;

  /** @ignore */
  constructor(options: VaultOptions, onPasscodeRequest?: onPasscodeRequestCallback, descriptor?: VaultDescriptor) {
    super(
      {
        ready: () => Promise.resolve(true),
      },
      options,
      descriptor
    );

    if (onPasscodeRequest) {
      this.onPasscodeRequest = onPasscodeRequest;
    }
  }

  public static getInstance(
    options: VaultOptions,
    onPasscodeRequest?: onPasscodeRequestCallback,
    descriptor?: VaultDescriptor
  ): MigratorVault {
    if (!MigratorVault.instance) {
      MigratorVault.instance = new MigratorVault(options, onPasscodeRequest, descriptor);
    }
    return MigratorVault.instance;
  }
}

export class VaultMigrator {
  /** @ignore */
  private migratorSession: MigratorVault;

  /**
   *
   * @usage
   * ```typescript
   * const legacyVaultConfig = {
   *  unlockOnAccess: true,
   *  hideScreenOnBackground: true,
   *  lockAfter: 5000,
   *  // and more ....
   * }
   * const migrator = new VaultMigrator(legacyVaultConfig, customPasscodePrompt);
   * ```
   * @param options The legacy vault configuration options
   * @param onPasscodeRequest An optional callback function that will be called when the vault attempts to request a passcode. The function returns a promise with a boolean that indicates if the passcode is being setup for the first time for the vault or not.
   * @param descriptor An optional interface that describes the legacy vault.
   */
  constructor(
    options: VaultOptions,
    onPasscodeRequest?: (isPasscodeSetRequest: boolean) => Promise<string | undefined>,
    descriptor?: VaultDescriptor
  ) {
    this.migratorSession = new MigratorVault(options, onPasscodeRequest, descriptor);
  }

  /**
   * Exports the data of the legacy vault in its entirety.
   *
   * @usage
   * ```typescript
   * const data = await migrator.exportVault();
   * console.log("@@VAULT DATA: ", JSON.stringify(data));
   * ```
   * @returns
   */
  async exportVault(): Promise<any | null> {
    if (!(await this.migratorSession.hasStoredSession())) {
      throw Error('no data in legacy vault');
    }

    await this.migratorSession.unlock();

    const vault = await this.migratorSession.getVault();
    const keys = await vault.getKeys();

    const vaultData: any = {};

    for (const key of keys) {
      vaultData[key] = await vault.getValue(key);
    }

    return vaultData;
  }

  /**
   * Clears out the legacy vault and removes it from the system.  Be sure to run {@link VaultMigrator.exportVault} before calling this method.
   *
   * @usage
   * ```typescript
   * const data = await migrator.exportVault();
   * await importVault(data);
   * await migrator.clear();
   * ```
   */
  async clear(): Promise<void> {
    const vault = await this.migratorSession.getVault();
    return vault.clear();
  }
}
