import { TaskQueue } from './TaskQueue';
import { VaultType } from './definitions';

export * from './definitions';

const taskQueue = TaskQueue.getInstance();

// Not properly implemented yet, resolve needs to be replaced with
// a json object parse from the string returned from from native and return type needs change from Promise<any>.
/**
 * @ignore
 */
export async function getConfigForVaultId(vaultId: string): Promise<any> {
  return new Promise((resolve, reject) => {
    cordova.exec(resolve, reject, 'IdentityVaultPlugin', 'getConfigForVaultId', [{ vaultId }]);
  });
}

// Not properly implemented yet on native side.
/**
 * @ignore
 */
export async function destroyVaultByVaultId(vaultId: string): Promise<void> {
  return new Promise((resolve, reject) => {
    cordova.exec(resolve, reject, 'IdentityVaultPlugin', 'destroyVaultByVaultId', [{ vaultId }]);
  });
}

// Not properly implemented yet, resolve needs to be replaced with switch that
// return Vaulttype enum value based on string from native.
/**
 * @ignore
 */
export async function getVaultTypeForVaultId(vaultId: string): Promise<VaultType> {
  return new Promise((resolve, reject) => {
    cordova.exec(resolve, reject, 'IdentityVaultPlugin', 'getVaultTypeForVaultId', [{ vaultId }]);
  });
}

class IdentityVaultBaseClass {
  protected vaultId: string;

  protected constructor(vaultId: string) {
    this.vaultId = vaultId;
  }

  protected static async getOrCreateVault(vaultId: string, options: any, vaultType: VaultType): Promise<void> {
    await taskQueue.addTask<void>(() => {
      return new Promise((resolve, reject) => {
        try {
          cordova.exec(
            resolve,
            (error) => {
              reject(error);
            },
            'IdentityVaultPlugin',
            'getOrCreate',
            [{ vaultId, options, vaultType }]
          );
        } catch (e: any) {
          reject(e);
        }
      });
    });
  }

  clear(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  async destroy(): Promise<void> {
    await taskQueue.addTask<void>(() => {
      return new Promise((resolve, reject) => {
        cordova.exec(resolve, reject, 'IdentityVaultPlugin', 'destroy', [{ vaultId: this.vaultId }]);
      });
    });
  }
  exists(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  exportVaultData(): Promise<any> {
    throw new Error('Method not implemented.');
  }
  getValue(key: string): Promise<string> {
    throw new Error('Method not implemented.');
  }
  getValues(keys: string[]): Promise<{ [key: string]: string }> {
    throw new Error('Method not implemented.');
  }
  importVaultData(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  isEmpty(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  isLocked(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  lock(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onError(handler: (e: any) => void): void {
    throw new Error('Method not implemented.');
  }
  onLock(handler: (e: any) => void): void {
    throw new Error('Method not implemented.');
  }
  onUnlock(handler: (e: any) => void): void {
    throw new Error('Method not implemented.');
  }
  removeValue(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  setLockAfterBackgroundedTime(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  setValue(key: string, value: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  setValues(values: { [key: string]: string }): Promise<void> {
    throw new Error('Method not implemented.');
  }
  unlock(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  getType(): Promise<VaultType> {
    throw new Error('Method not implemented.');
  }
}

/**
 * @ignore
 */
export class DeviceSecurityVault extends IdentityVaultBaseClass {
  private static instances: { [vaultId: string]: DeviceSecurityVault } = {};
  private static getInstance(vaultId: string): DeviceSecurityVault {
    if (!DeviceSecurityVault.instances[vaultId]) {
      DeviceSecurityVault.instances[vaultId] = new DeviceSecurityVault(vaultId);
    }
    return DeviceSecurityVault.instances[vaultId];
  }

  private constructor(vaultId: string) {
    super(vaultId);
  }

  static async getOrCreate(vaultId: string, options: any): Promise<DeviceSecurityVault> {
    await IdentityVaultBaseClass.getOrCreateVault(vaultId, options, VaultType.DeviceSecurity);
    return DeviceSecurityVault.getInstance(vaultId);
  }

  async destroy(): Promise<void> {
    await super.destroy();
    delete DeviceSecurityVault.instances[this.vaultId];
  }

  async getType(): Promise<VaultType> {
    return VaultType.DeviceSecurity;
  }
}
