/**
 * @ignore
 */
export enum VaultType {
  DeviceSecurity = 'DeviceSecurity',
}

enum LogLevel {
  Debug = 'DEBUG',
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR',
}

interface BaseIdentityVaultOptions {
  logLevel: LogLevel;
  unlockOnAccess: boolean;
  // unlockOnLoad: boolean; // Leave out for now
}

/**
 * @ignore
 */
export interface DeviceSecurityVaultOptions extends BaseIdentityVaultOptions {
  allowBiometrics: boolean; // Use Biometrics if available
  allowSystemPasscode: boolean; // Use System Passcode if available
  android: {
    requireClass3Security: boolean; // Enforce that a "Strong Vault" is created
  };
  ios: {
    // Nothing yet
  };
}
