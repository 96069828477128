import type {
  DefaultSession,
  IdentityVault,
  IdentityVaultUser,
  IonicNativeAuthPlugin,
  VaultDescriptor,
  VaultOptions,
  PluginConfiguration,
  LockEvent,
  VaultError,
  VaultConfig,
  BiometricType,
  SupportedBiometricType,
} from './definitions';
import { AuthMode, VaultErrorCodes } from './definitions';

declare let IonicNativeAuth: IonicNativeAuthPlugin;

/**
 * @hidden
 *
 * @ignore
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export class IonicIdentityVaultUser<T extends {} = DefaultSession> implements IdentityVaultUser<T> {
  private vault!: IdentityVault;
  private vIonicNativeAuth?: IonicNativeAuthPlugin;
  private _readyPromise: Promise<any>;
  private _config!: PluginConfiguration;
  private _readyResolve!: (value?: void | PromiseLike<void>) => void;
  private _readyReject!: (reason?: any) => void;
  private _readyCalled = false;
  private descriptor: VaultDescriptor;
  private session?: T;

  constructor(
    public platform: { ready: () => Promise<any> },
    private readonly options: VaultOptions,
    descriptor?: VaultDescriptor
  ) {
    this.descriptor = descriptor || { username: '_lastUser', vaultId: 'default' };
    this._readyPromise = this.initializeVault();
  }

  get token(): string | undefined {
    const session: any = this.session;
    return session?.token;
  }

  get username(): string | undefined {
    const session: any = this.session;
    return session?.username;
  }

  get config(): VaultConfig {
    if (!this._config) {
      return undefined as any;
    }
    let authMode: AuthMode | undefined;
    const bioEnabled = this._config.isBiometricsEnabled;
    const passEnabled = this._config.isPasscodeEnabled;
    const secureStorageMode = this._config.isSecureStorageModeEnabled;
    if (secureStorageMode) {
      authMode = AuthMode.SecureStorage;
    } else if (bioEnabled && passEnabled) {
      authMode = AuthMode.BiometricAndPasscode;
    } else if (bioEnabled && !passEnabled) {
      authMode = AuthMode.BiometricOnly;
    } else if (!bioEnabled && passEnabled) {
      authMode = AuthMode.PasscodeOnly;
    } else if (!bioEnabled && !passEnabled) {
      authMode = AuthMode.InMemoryOnly;
    }
    return {
      authMode: authMode,
      isPasscodeSetupNeeded: this._config.isPasscodeSetupNeeded,
      lockAfter: this._config.lockAfter,
      hideScreenOnBackground: this._config.hideScreenOnBackground,
    };
  }

  // overidable event handlers
  onVaultLocked(_event: LockEvent): void {
    /* Do Nothing */
  }
  onSessionRestoreError(_err: VaultError): void {
    /* Do Nothing */
  }
  onUnlockOnReadyError(_err: VaultError): void {
    /* Do Nothing */
  }
  onVaultUnlocked(_config: VaultConfig): void {
    /* Do Nothing */
  }
  onVaultReady(_config: VaultConfig): void {
    /* Do Nothing */
  }
  onSetupError(_error: VaultError): void {
    /* Do Nothing */
  }
  onConfigChange(_config: VaultConfig): void {
    /* Do Nothing */
  }
  onSessionRestored(_session: T): void {
    /* Do Nothing */
  }
  async onPasscodeRequest(_isPasscodeSetRequest: boolean): Promise<string | undefined> {
    return;
  }

  private async onReady(vault: IdentityVault) {
    if (this._readyCalled) {
      return;
    }
    this._readyCalled = true;
    this.vault = vault;
    const inUse = await this.vault.isInUse();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._config = vault.config!;
    const locked = await this.vault.isLocked();

    let restoreSessionError!: VaultError;

    try {
      if (this.options.restoreSessionOnReady && inUse) {
        await this._restoreSession();
      }
    } catch (e) {
      restoreSessionError = e as VaultError;
    }

    let unlockOnReadyError!: VaultError;
    try {
      if (locked && this.options.unlockOnReady) {
        await this._unlock();
      }
    } catch (e) {
      unlockOnReadyError = e as VaultError;
    }

    // Note Swallow all errors in init like Vault is Locked Etc.
    try {
      if (!inUse) {
        await this._trySetAuthMode(this.options.authMode);
      }
    } catch (e) {
      // Do Nothing
    }
    this._readyResolve();
    this.onVaultReady(this.config);
    if (restoreSessionError) {
      this.onSessionRestoreError(restoreSessionError);
    }
    if (unlockOnReadyError) {
      this.onUnlockOnReadyError(restoreSessionError);
    }
  }

  private onLock(event: LockEvent) {
    this.session = undefined;
    this.onVaultLocked(event);
  }

  private async onUnlock(config: PluginConfiguration) {
    await this.ready();
    this._config = config;
    this.onVaultUnlocked(this.config);
  }

  private onError(error: VaultError) {
    this._readyReject(error);
    this.onSetupError(error);
  }

  private onConfig(config: PluginConfiguration) {
    this._config = config;
    this.onConfigChange(this.config);
  }

  public async ready(): Promise<void> {
    return this._readyPromise;
  }

  private async _unlock(authMode?: AuthMode): Promise<void> {
    const locked = await this.vault.isLocked();
    if (!locked) {
      return;
    }
    authMode = authMode !== undefined && authMode !== AuthMode.BiometricOrPasscode ? authMode : this.config.authMode;
    switch (authMode) {
      case AuthMode.BiometricOnly:
        return this.vault.unlock();
      case AuthMode.PasscodeOnly:
        return this.unlockWithPasscode();
      case AuthMode.BiometricAndPasscode:
        try {
          await this.vault.unlock();
          return;
        } catch (e: any) {
          const handleableErrors = [
            VaultErrorCodes.AuthFailed,
            VaultErrorCodes.BiometricsNotEnabled,
            VaultErrorCodes.UserCanceledInteraction,
            VaultErrorCodes.InvalidatedCredential,
          ];
          if (handleableErrors.indexOf(e.code) > -1) {
            await this.unlockWithPasscode();
            // The user removed fingerprints/faceID and so the bio creds are gone
            // if they are using passcode auth we can resave the credential to autoreset
            // the mode to PasscodeOnly if bio is no longer available.
            if (e.code === VaultErrorCodes.InvalidatedCredential) {
              const session = await this.restoreSession();
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              await this.saveSession(session!);
            }
            return;
          }
          throw e;
        }
    }
  }

  public async unlock(authMode?: AuthMode): Promise<void> {
    await this.ready();
    return this._unlock(authMode);
  }

  private async unlockWithPasscode() {
    const passcode = await this.onPasscodeRequest(false);
    return this.vault.unlock(true, passcode);
  }

  private async _setPasscode() {
    const locked = await this.vault.isLocked();
    if (locked) {
      // eslint-disable-next-line no-throw-literal
      throw { code: VaultErrorCodes.VaultLocked, message: 'Operation not allowed while vault locked.' };
    }
    const passcode = await this.onPasscodeRequest(true);
    return this.vault.setPasscode(passcode);
  }

  public async setPasscode(): Promise<void> {
    await this.ready();
    return this._setPasscode();
  }

  public async getSession(): Promise<T | undefined> {
    await this.ready();
    if (this.options.unlockOnAccess) {
      await this._unlock();
    }
    return this.session;
  }

  private async _restoreSession(): Promise<T | undefined> {
    const inUse = await this.vault.isInUse();
    if (!inUse) {
      return;
    }
    if (this.options.unlockOnAccess) {
      await this._unlock();
    }
    this.session = await this.vault.getValue('session');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.onSessionRestored(this.session!);
    return this.session;
  }

  public async restoreSession(): Promise<T | undefined> {
    await this.ready();
    return this._restoreSession();
  }

  public async saveSession(session: T): Promise<void> {
    await this.ready();
    if (this.config.isPasscodeSetupNeeded) {
      await this.setPasscode();
    }
    try {
      await this.vault.storeValue('session', session);
    } catch (e: any) {
      // Catch the case where Biometrics with Passcode Fallback
      // was enabled but the user disabled passcode or removed their
      // fingerprints/faceid and try to recover by setting to passcode only
      // mode.
      if (e.code === VaultErrorCodes.SecurityNotAvailable) {
        const authMode = await this.getAuthMode();
        if (authMode === AuthMode.BiometricAndPasscode) {
          await this.setAuthMode(AuthMode.PasscodeOnly);
          return this.saveSession(session);
        }
      }
      throw e;
    }
    this.session = session;
  }

  public async login(session: T, authMode?: AuthMode): Promise<void> {
    await this.ready();
    await this.logout();
    await this._trySetAuthMode(authMode);
    return this.saveSession(session);
  }

  public async getVault(): Promise<IdentityVault> {
    await this.ready();
    return this.vault;
  }

  private async initializeVault(): Promise<void> {
    await this.platform.ready();

    if (this.vault != null) {
      return Promise.resolve();
    }

    if (this.vIonicNativeAuth == null) {
      this.vIonicNativeAuth = this.getPlugin();
    }

    const readyPromise = new Promise<void>((resolve, reject) => {
      this._readyResolve = resolve;
      this._readyReject = reject;
    });

    const {
      lockAfter,
      hideScreenOnBackground,
      shouldClearVaultAfterTooManyFailedAttempts,
      allowSystemPinFallback,
      androidPromptNegativeButtonText,
      androidPromptTitle,
      androidPromptSubtitle,
      androidPromptDescription,
      androidPINPromptTitle,
      androidPINPromptSubtitle,
      androidPINPromptConfirmTitle,
      androidPINPromptVerifyTitle,
      androidPINPromptConfirmButtonText,
      androidPINPromptNegativeButtonText,
      iosPromptText,
    } = this.options;

    this.vault = this.vIonicNativeAuth.getVault({
      lockAfter,
      hideScreenOnBackground,
      shouldClearVaultAfterTooManyFailedAttempts,
      allowSystemPinFallback,
      androidPromptNegativeButtonText,
      androidPromptTitle,
      androidPromptSubtitle,
      androidPromptDescription,
      androidPINPromptTitle,
      androidPINPromptSubtitle,
      androidPINPromptConfirmTitle,
      androidPINPromptVerifyTitle,
      androidPINPromptConfirmButtonText,
      androidPINPromptNegativeButtonText,
      iosPromptText,
      ...this.descriptor,
      onLock: this.onLock.bind(this),
      onConfig: this.onConfig.bind(this),
      onError: this.onError.bind(this),
      onUnlock: this.onUnlock.bind(this),
      onReady: this.onReady.bind(this),
    });

    return readyPromise;
  }

  public getPlugin(): IonicNativeAuthPlugin {
    return IonicNativeAuth;
  }

  public async getBiometricType(): Promise<BiometricType> {
    await this.ready();
    return this.vault.getBiometricType();
  }

  public async getAvailableHardware(): Promise<SupportedBiometricType[]> {
    await this.ready();
    return this.vault.getAvailableHardware();
  }

  public async lockOut(): Promise<void> {
    await this.ready();
    await this.vault.lock();
    this.session = undefined;
  }

  public async logout(): Promise<void> {
    await this.ready();
    await this.vault.clear();
    this.session = undefined;
    this._config = await this.vault.getConfig();
  }

  public async hasStoredSession(): Promise<boolean> {
    await this.ready();
    return this.vault.isInUse();
  }

  public async setBiometricsEnabled(isBiometricsEnabled: boolean): Promise<void> {
    await this.ready();
    return this._setBiometricsEnabled(isBiometricsEnabled);
  }

  private async _setBiometricsEnabled(isBiometricsEnabled: boolean): Promise<void> {
    return this.vault.setBiometricsEnabled(isBiometricsEnabled);
  }

  public async setHideScreenOnBackground(enabled: boolean): Promise<void> {
    await this.ready();
    return this.vault.setHideScreenOnBackground(enabled);
  }

  public async setPasscodeEnabled(isPasscodeEnabled: boolean): Promise<void> {
    await this.ready();
    await this._setPasscodeEnabled(isPasscodeEnabled);
  }

  private async _setPasscodeEnabled(isPasscodeEnabled: boolean): Promise<void> {
    await this.vault.setPasscodeEnabled(isPasscodeEnabled);
    this._config = await this.vault.getConfig();
    if (this.config.isPasscodeSetupNeeded) {
      await this._setPasscode();
    }
  }

  public async isBiometricsEnabled(): Promise<boolean> {
    await this.ready();
    return this.vault.isBiometricsEnabled();
  }

  public async isBiometricsAvailable(): Promise<boolean> {
    await this.ready();
    return this.vault.isBiometricsAvailable();
  }

  public async isBiometricsSupported(): Promise<boolean> {
    await this.ready();
    return this.vault.isBiometricsSupported();
  }

  public async isSecureStorageModeEnabled(): Promise<boolean> {
    await this.ready();
    return this.vault.isSecureStorageModeEnabled();
  }

  public async isPasscodeEnabled(): Promise<boolean> {
    await this.ready();
    return this.vault.isPasscodeEnabled();
  }

  private async _setAuthMode(authMode?: AuthMode) {
    authMode = authMode !== undefined ? authMode : this.config.authMode;
    if (authMode === this.config.authMode) {
      return;
    }
    switch (authMode) {
      case AuthMode.BiometricOnly:
        await this._setBiometricsEnabled(true);
        await this._setPasscodeEnabled(false);
        break;
      case AuthMode.PasscodeOnly:
        await this._setPasscodeEnabled(true);
        await this._setBiometricsEnabled(false);
        break;
      case AuthMode.BiometricAndPasscode:
        await this._setPasscodeEnabled(true);
        await this._setBiometricsEnabled(true);
        break;
      case AuthMode.BiometricOrPasscode:
        try {
          await this._setBiometricsEnabled(true);
          await this._setPasscodeEnabled(false);
        } catch (error) {
          await this._setPasscodeEnabled(true);
        }
        break;
      case AuthMode.InMemoryOnly:
        await this._setPasscodeEnabled(false);
        await this._setBiometricsEnabled(false);
        await this.vault.setSecureStorageModeEnabled(false);
        break;
      case AuthMode.SecureStorage:
        // Note: Setting this mode automatically disables the other modes in native code.
        await this.vault.setSecureStorageModeEnabled(true);
        break;
      default:
        // eslint-disable-next-line no-throw-literal
        throw { code: VaultErrorCodes.InvalidAuthMode, message: 'Invalid AuthMode' };
    }
  }

  private async _trySetAuthMode(authMode?: AuthMode) {
    try {
      await this._setAuthMode(authMode);
    } catch (error: any) {
      if (error.code !== VaultErrorCodes.BiometricsNotEnabled && error.code !== VaultErrorCodes.SecurityNotAvailable) {
        throw error;
      }
    }
  }

  public async setAuthMode(authMode?: AuthMode): Promise<void> {
    await this.ready();
    return this._setAuthMode(authMode);
  }

  public async getAuthMode(): Promise<AuthMode> {
    await this.ready();
    this._config = await this.vault.getConfig();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.config.authMode!;
  }
}
