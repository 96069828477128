/**
 * The meaning of the error code in the thrown {@link VaultError}.
 */
export enum VaultErrorCodes {
  /**
   * An unknown error happened.
   * @constant `0`
   */
  Unknown,
  /**
   * The operation failed because the vault was locked.
   * @constant `1`
   */
  VaultLocked,
  /**
   * The operation failed because the vault was unavailable.
   * The most likely cause of this error is that a vault has not been configured.
   * @constant `2`
   */
  VaultUnavailable,
  /**
   * The operation failed because the some of the vault provided arguments were invalid.
   * @constant `3`
   */
  InvalidArguments,
  /**
   * The credentials were invalidated. This can happen when a user changes biometrics or passcode. For Vaults with the DeviceSecurityType of Both if the biometrics change the vault will be invalidated on iOS but will not be on Android.
   * @constant `4`
   */
  InvalidatedCredential,
  /**
   * Biometric security is unavailable due to a passcode not being set up at the system level.
   * In order to use biometric identification on the device a system level passcode must be set up by the user.
   * @constant `5`
   */
  SecurityNotAvailable,
  /**
   * User authentication failed. This can occur when the user has denied use of biometrics or force closed the app during a biometric prompt.
   * You can call the Device.isBiometricsAllowed method to determine if the user has denied access to Biometrics.
   * @constant `6`
   */
  AuthFailed,
  /**
   * Too many failed authentication attempts made against the custom passcode vault, so the vault was cleared and user will need to login again.
   * @constant `7`
   */
  TooManyFailedAttempts,
  /**
   * The user cancelled the native authentication dialog.
   *
   * **Note**: On iOS, this error code will also be thrown in the event that the native authentication dialog fails as a result of too many failed attempts.
   * The user will be forced to cancel the dialog, triggering this error code.
   *
   * On Android, this error also will be thrown if using {@link DeviceSecurityType.SystemPasscode} or {@link DeviceSecurityType.Both} in the event of too many failed attempts.
   * Its currently not possible to get the nature of failures from the native authentication dialog, and similar to iOS, the user will be forced to cancel the dialog, triggering this error code.
   * @constant `8`
   */
  UserCanceledInteraction,
  /**
   * The user provided mismatched passcodes.
   * @constant `9`
   */
  MismatchedPasscode,
  /**
   * The operation requires passcode to be setup but it isn't set yet. Call {@link Vault.setCustomPasscode} to set it.
   * @constant `10`
   */
  MissingPasscode,
  /**
   * The operation failed because the application tried to unlock the vault with passcode authentication,
   * but the vault is not configured to allow passcode authentication.
   * @constant `11`
   */
  PasscodeNotEnabled,
  /**
   * The key was not found. This can happen when a user changes biometrics or passcode.
   * @constant `12`
   */
  KeyNotFound,
  /**
   * The operation failed because biometric authentication is not enabled.
   * This can occur when biometrics is not supported by the device
   * or when biometrics has not been configured for the device or vault.
   * @constant `13`
   */
  BiometricsNotEnabled,
  /**
   * @ignore
   */
  InvalidAuthMode,
  /**
   * Biometrics have not been authed yet (Android only)
   * @constant `15`
   */
  MissingBiometrics,
  /**
   * {@link DeviceSecurityType.SystemPasscode} for {@link DeviceSecurityType} not available on this Android device.
   * @constant `16`
   */
  AndroidSystemPasscodeUnavailable,
  /**
   * Biometrics have been locked out, usually because of too many failed attempts.
   *
   * **Note:** This error will never be thrown if using {@link DeviceSecurityType.SystemPasscode} or {@link DeviceSecurityType.Both}.
   * Its currently not possible to get the nature of failures from the native authentication dialog, so the only error that would be thrown is {@link VaultErrorCodes.UserCanceledInteraction} as the user
   * is forced to cancel the prompt.
   *
   * @constant `17`
   */
  AndroidBiometricsLockedOut,
  /**
   * Biometrics have been locked out, usually because of too many failed attempts.
   *
   * @constant `18`
   */
  iOSBiometricsLockedOut,
  /**
   * An unexpected error has occurred in the Android keystore.
   * Some android devices (such as the Samsung S10) will return this error code when
   * a maximum number of failed attempts is reached and the device must be restarted.
   * See: [Max Uses Per Boot](https://source.android.com/security/keystore/tags#max_uses_per_boot)
   *
   * @constant `19`
   */
  AndroidUnexpectedKeystoreError,
}

/**
 * The meaning of the error code in the thrown {@link DeviceError}.
 */
export enum DeviceErrorCodes {
  /**
   * An unknown error happened.
   * @constant `0`
   */
  Unknown,
  /**
   * The operation failed because the some of the vault provided arguments were invalid.
   * @constant `1`
   */
  InvalidArguments,
  /**
   * The user cancelled the native authentication dialog.
   *
   * **Note**: On iOS, this error code will also be thrown in the event that the native authentication dialog fails as a result of too many failed attempts.
   * The user will be forced to cancel the dialog, triggering this error code.
   *
   * @constant `2`
   */
  UserCanceledInteraction,
  /**
   * Biometric security is unavailable due to a passcode not being set up at the system level.
   * In order to use biometric identification on the device a system level passcode must be set up by the user.
   * @constant `3`
   */
  SecurityNotAvailable,
  /**
   * User authentication failed.
   * @constant `4`
   */
  AuthFailed,
  /**
   * Biometrics have been locked out, usually because of too many failed attempts.
   *
   * @constant `5`
   */
  BiometricsLockedOut,
  /**
   * The operation failed because biometric authentication is not enabled.
   * This can occur when biometrics is not supported by the device
   * or when biometrics has not been configured for the device or vault.
   * @constant `6`
   */
  BiometricsNotEnabled,
}

/**
 * An error that can be thrown by the plugin.
 *
 * @example
 * try {
 *  vault.unlock();
 * } catch(e) {
 *   switch (e.code) {
 *     case VaultErrorCodes.AuthFailed:
 *       alert('You failed to authenticate!');
 *       break;
 *     case VaultErrorCodes.UserCanceledInteraction:
 *       alert('You cancelled the face id prompt!');
 *       break;
 *     default:
 *       throw e;
 *   }
 * }
 */
export interface VaultError {
  /**
   * - Type: `string`
   *
   * A text description of the error that occurred.
   */
  message: string;
  /**
   * - Type: {@link VaultErrorCodes}
   *
   * The error code enum representing the error.
   */
  code: VaultErrorCodes;
}

/**
 * An error that can be thrown in the Device class.
 *
 * @example
 * try {
 *  await Device.showBiometricPrompt(promptConfig);
 * } catch(e) {
 *   switch (e.code) {
 *     case DeviceErrorCodes.AuthFailed:
 *       alert('You failed to authenticate!');
 *       break;
 *     case DeviceErrorCodes.UserCanceledInteraction:
 *       alert('You cancelled the face id prompt!');
 *       break;
 *     default:
 *       throw e;
 *   }
 * }
 */
export interface DeviceError {
  /**
   * - Type: `string`
   *
   * A text description of the error that occurred.
   */
  message: string;
  /**
   * - Type: {@link DeviceErrorCodes}
   *
   * The error code enum representing the error.
   */
  code: DeviceErrorCodes;
}

/**
 * The possible values returned by {@link Device.getAvailableHardware}
 */
export enum SupportedBiometricType {
  /** The device supports fingerprint scanning. */
  Fingerprint = 'fingerprint',
  /** The device supports facial recognition. */
  Face = 'face',
  /** The device supports iris scanning. */
  Iris = 'iris',
}

/**
 * Possible device biometric strength levels on Android (always `strong` on iOS).
 * [More Information](https://source.android.com/security/biometric/measure#tiered-authentication).
 */
export enum BiometricSecurityStrength {
  /** Refers to Class 2 - Weak biometric security */
  Weak = 'weak',
  /** Refers to Class 3 - Strong biometric security */
  Strong = 'strong',
}

/**
 * Possible app biometric permissions states on iOS (always `granted` on Android and on iOS with TouchID).
 */
export enum BiometricPermissionState {
  /** App has been granted permission to use FaceID */
  Granted = 'granted',
  /** App has been denied permission to use FaceID */
  Denied = 'denied',
  /** User has not yet been prompted to allow FaceID */
  Prompt = 'prompt',
}

/**
 * When type is set to 'DeviceSecurity', determines which aspects of the device to use to secure the vault.
 * Note: Android only supports `SystemPasscode` on Android 11 and greater.
 * @default `Both`
 */
export enum DeviceSecurityType {
  /** Will allow a system passcode to secure the vault. On Android, only supported on Android 11 and greater. */
  SystemPasscode = 'SystemPasscode',
  /** WIll allow biometric hardware tos secure the vault. */
  Biometrics = 'Biometrics',
  /** WIll allow both SystemPasscode or Biometrics as an option to secure the vault. */
  Both = 'Both',
  /** No biometric security option will be used. */
  None = 'None',
}

/**
 * The type of vault
 */
export enum VaultType {
  /**
   * No additional security is required in the app.
   * This vault type cannot be locked/unlock so methods like `lock`, `unlock`, `isLocked`, and `lockAfterbackgrounded` have no effect.
   *
   * On iOS, this vault is synchronized via iCloud or new device setup.
   * */
  SecureStorage = 'SecureStorage',
  /**
   * Uses additional device features to add an additional layer of security while the user is in the app.
   *
   * On iOS, this vault is NOT synchronized via iCloud or new device setup.
   * */
  DeviceSecurity = 'DeviceSecurity',
  /**
   * User will set a custom passcode that will be used to access the vault.
   *
   * On iOS, this vault is NOT synchronized via iCloud or new device setup.
   * */
  CustomPasscode = 'CustomPasscode',
  /** Data will persist only while the application is in memory.
   * This vault type cannot be locked/unlock so methods like `lock`, `unlock`, `isLocked`, and `lockAfterbackgrounded` have no effect.
   *
   *  On iOS, this vault is NOT synchronized via iCloud or new device setup.
   */
  InMemory = 'InMemory',
}

/**
 * For Android, when type is {@link VaultType.DeviceSecurity} and deviceSecurityType is {@link DeviceSecurityType.Both}, this options specifies if you want to
 * prefer a Strong Cryptographic Vault or the System Passcode fallback when they both aren't available.
 * @default `StrongVault`
 */
export enum AndroidBiometricCryptoPreference {
  /** Prefer to use a strong cryptographic vault. */
  StrongVault = 'StrongVault',
  /** Prefer the system passcode fallback. */
  SystemPasscode = 'SystemPasscode',
}

// OLD METHODS

/**
 * The type of authentication the vault should be configured to allow.
 * @deprecated For use in legacy Vault Migrator only.
 * @ignore
 */
export enum AuthMode {
  /**
   * Biometrics authentication should only be allowed
   */
  BiometricOnly,

  /**
   * Passcode authentication should only be allowed
   */
  PasscodeOnly,

  /**
   * Both biometric and passcode authentication should be allowed
   */
  BiometricAndPasscode,

  /**
   * Both biometric and passcode authentication should be disabled.
   * With this setting all data in the vault will be cleared on lock or
   * if the app is closed. Stored data is kept only in memory.
   */
  InMemoryOnly,

  /**
   * Use biometrics if it is available, otherwise use passcode
   */
  BiometricOrPasscode,

  /**
   * Both biometric and passcode authentication will be disabled but any stored values
   * will persist and be stored securely at rest using the keychain and will be available
   * without needing to authenticate via passcode or biometrics when the device is unlocked.
   */
  SecureStorage,
}

/**
 * The interface for the Default Session.
 * Extend this interface to make a custom session
 *
 * @example
 * MyCustomSession extends DefaultSession {
 *   email: string;
 *   age: number;
 *   nicknames: string[];
 * }
 *
 * @ignore
 */
export interface DefaultSession {
  username: string;
  token: string;
}

/**
 * The possible values returned by {@link getBiometricType}
 * @property `touchID` | `faceID` | `none`
 * @ignore
 */
export type BiometricType = 'touchID' | 'faceID' | 'none';

/**
 * An interface that describes the vault. In {@link IdentityVaultUser} this
 * defaults to `_lastUser` & `default` you can optionally add this to the constructor
 * to accomplish advanced use cases multi-tenant vaults.
 *
 * @deprecated For use in legacy Vault Migrator only.
 * @ignore
 */
export interface VaultDescriptor {
  /**
   * The username for the vault you wish to access
   */
  username: string;

  /**
   * The vaultId for the vault you wish to access
   */
  vaultId: string;
}

/**
 * The configuration returned from {@link IonicNativeAuthPlugin} when {@link getConfig} is called.
 *
 * @ignore
 */
export interface PluginConfiguration {
  /**
   * The values the uniquely identify the vault
   */
  descriptor: VaultDescriptor;

  /**
   * Whether biometric authentication is enabled for the vault
   */
  isBiometricsEnabled: boolean;

  /**
   * Whether passcode authentication is enabled for the vault
   */
  isPasscodeEnabled: boolean;

  /**
   * Whether {@link AuthMode.SecureStorage} is enabled for the vault
   */
  isSecureStorageModeEnabled: boolean;

  /**
   * Whether a passcode needs to be set with {@link setPasscode}
   */
  isPasscodeSetupNeeded: boolean;

  /**
   * Obscures the app when backgrounded to avoid leaking sensitive information, such as financial statements or balances.
   * App screenshots won't work on Android 10 devices using gesture navigation if this option is set to true.
   */
  hideScreenOnBackground: boolean;

  /**
   * The amount of number of milliseconds the app can be in the background for until the vault locks
   */
  lockAfter: number;
}

/**
 * @hidden
 * @deprecated For use in legacy Vault Migrator only.
 * @ignore
 */
export interface VaultOptionsBase {
  /**
   * The {@link AuthMode} to configure a new vault with. Note: This will only
   * affect newly created vaults. Vaults with existing data and configuration will use
   * there previous configuration. They can be change by unlocking and then chaning the AuthMode.
   *
   * @default - {@link BiometricAndPasscode}
   *
   */
  authMode?: AuthMode;

  /**
   * The amount of number of milliseconds the app can be in the background for until the vault locks.
   * A value of 0 means the vault won't lock in the background.
   * @default 0
   */
  lockAfter?: number;

  /**
   * Obscures the app when backgrounded to avoid leaking sensitive information, such as financial statements or balances.
   * App screenshots won't work on Android 10 devices using gesture navigation if this option is set to true.
   * @default false
   */
  hideScreenOnBackground?: boolean;

  /**
   * After too many failed authentication attempts, should the vault be cleared?
   * @default true
   */
  shouldClearVaultAfterTooManyFailedAttempts?: boolean;

  /**
   * If biometric auth fails, allow system pin fallback.
   * @default false
   */
  allowSystemPinFallback?: boolean;

  /**
   * The title text for the Android authentication prompt.
   * @default "Please Authenticate"
   */
  androidPromptTitle?: string;

  /**
   * The subtitle text for the Android authentication prompt.
   * @default null
   */
  androidPromptSubtitle?: string;

  /**
   * The description text for the Android authentication prompt.
   * @default null
   */
  androidPromptDescription?: string;

  /**
   * The cancel text for the Android authentication prompt.
   * @default "Cancel"
   */
  androidPromptNegativeButtonText?: string;

  /**
   * The subtitle text for the Android pin authentication prompt.
   * @default null
   */
  androidPINPromptSubtitle?: string;
  /**
   * The title text for verifying a pin in the Android pin authentication prompt.
   * @default "Please Authenticate"
   */
  androidPINPromptVerifyTitle?: string;
  /**
   * The title text for entering a new pin in the Android pin authentication prompt.
   * @default "Please Authenticate"
   */
  androidPINPromptTitle?: string;
  /**
   * The title text for confirming a new pin in the Android pin authentication prompt.
   * @default "Please Authenticate"
   */
  androidPINPromptConfirmTitle?: string;
  /**
   * The confirm button text for the Android pin authentication prompt.
   * @default "OK"
   */
  androidPINPromptConfirmButtonText?: string;
  /**
   * The cancel button text for the Android pin authentication prompt.
   * @default "Cancel"
   */
  androidPINPromptNegativeButtonText?: string;

  /**
   * For iOS, the prompt text for the biometric prompt
   * @default "Authenticate to log in"
   */
  iosPromptText?: string;
}

/**
 * The options passed in to initialize the vault.
 *
 * @deprecated For use in legacy Vault Migrator only.
 * @ignore
 */
export interface VaultOptions extends VaultOptionsBase {
  /**
   * Whether or not to attempt to automatically restore the session when the vault is ready
   * If {@link restoreSessionOnReady} and {@link unlockOnAccess} are
   * both true the vault will be immediately unlocked & the session restored if onReady if the
   * vault is in use.
   * @default false
   */
  restoreSessionOnReady?: boolean;

  /**
   * Whether or not to attempt to automatically unlock the vault when a value is accessed.
   * If {@link restoreSessionOnReady} and {@link unlockOnAccess} are
   * both true the vault will be immediately unlocked & the session restored if onReady if the
   * vault is in use.
   * @default false
   */
  unlockOnAccess?: boolean;

  /**
   * Whether or not to attempt to automatically unlock the vault when the vault is ready
   * @default false
   */
  unlockOnReady?: boolean;
}

/**
 * The configuration file returned to event handlers such as
 * {@link onConfigChange} and {@link onVaultReady}.
 * @ignore
 */
export interface VaultConfig extends VaultOptionsBase {
  /**
   * Whether a passcode needs to be set with {@link setPasscode}
   */
  isPasscodeSetupNeeded: boolean;
}

/**
 * The options passed the the {@link IonicNativeAuthPlugin} when creating a vault with {@link getVault}
 * @ignore
 */
export interface PluginOptions extends VaultDescriptor {
  /**
   * The amount of number of milliseconds the app can be in the background for until the vault locks
   */
  lockAfter?: number;

  /**
   * Obscures the app when backgrounded to avoid leaking sensitive information, such as financial statements or balances.
   * App screenshots won't work on Android 10 devices using gesture navigation if this option is set to true.
   *
   * @default false
   */
  hideScreenOnBackground?: boolean;

  /**
   * After too many failed authentication attempts, should the vault be cleared?
   * @default true
   */
  shouldClearVaultAfterTooManyFailedAttempts?: boolean;

  /**
   * If biometric auth fails, allow system pin fallback.
   *
   * Please note: when Android devices are set to allow system pin fallback, the behavior of the biometrics prompt changes.
   * The OS biometric prompt will not report that biometrics failed nor that the user has been locked out of biometrics.
   * It is only currently possible to know the user either canceled the authentication prompt, or was successful in verifying.
   *
   * @default false
   */
  allowSystemPinFallback?: boolean;

  /**
   * The title text for the Android authentication prompt.
   * @default "Please Authenticate"
   */
  androidPromptTitle?: string;

  /**
   * The subtitle text for the Android authentication prompt.
   * @default null
   */
  androidPromptSubtitle?: string;

  /**
   * The description text for the Android authentication prompt.
   * @default null
   */
  androidPromptDescription?: string;

  /**
   * The cancel text for the Android authentication prompt.
   * @default "Cancel"
   */
  androidPromptNegativeButtonText?: string;

  /**
   * The subtitle text for the Android pin authentication prompt.
   * @default null
   */
  androidPINPromptSubtitle?: string;
  /**
   * The title text for verifying a pin in the Android pin authentication prompt.
   * @default "Please Authenticate"
   */
  androidPINPromptVerifyTitle?: string;
  /**
   * The title text for entering a new pin in the Android pin authentication prompt.
   * @default "Please Authenticate"
   */
  androidPINPromptTitle?: string;
  /**
   * The title text for confirming a new pin in the Android pin authentication prompt.
   * @default "Please Authenticate"
   */
  androidPINPromptConfirmTitle?: string;
  /**
   * The confirm button text for the Android pin authentication prompt.
   * @default "OK"
   */
  androidPINPromptConfirmButtonText?: string;
  /**
   * The cancel button text for the Android pin authentication prompt.
   * @default "Cancel"
   */
  androidPINPromptNegativeButtonText?: string;

  /**
   * For iOS, the localized explanation for authentication shown in the dialog presented to the user.
   * @default "Authenticate to log in"
   */
  iosPromptText?: string;

  /**
   * A handler that will receive events any time the vault is locked
   */
  onLock?(event: LockEvent): any;

  /**
   * A handler that will receive events any time the vault is unlocked
   */
  onUnlock?(event: PluginConfiguration): any;

  /**
   * A handler that will receive events any time the vault is configuration is changed
   */
  onConfig?(event: PluginConfiguration): any;

  /**
   * Called when there is an error during vault setup
   *
   * @param error - The {@link VaultError} that occurred.
   *
   */
  onError?(error: VaultError): any;

  /**
   * Called when the vault is configured and available.
   *
   * @param vault - The {@link IdentityVault}  instance.
   *
   */
  onReady?(vault: IdentityVault): any;
}

/**
 * The underlying vault API. You can gain direct access to the vault for more advanced usage by
 * using the {@link getVault IdentityVaultUser.getVault} method.
 * @ignore
 */
export interface IdentityVault {
  /**
   * The current configuration of the vault will stay current as long as {@link unsubscribe} is not called.
   */
  config?: PluginConfiguration;

  /**
   * Unsubscribe the instance from events about the vault.
   *
   * @example
   * vault.unsubscribe();
   *
   */
  unsubscribe(): Promise<void>;

  /**
   * Clear all vault data including stored tokens, values, and passcodes. The vault will be empty and unlocked after this.
   *
   * @example
   * vault.clear()
   */
  clear(): Promise<void>;

  /**
   * Lock the vault clearing the contents from memory and requiring biometrics or passcode to unlock
   *
   * @example
   * vault.lock()
   */
  lock(): Promise<void>;

  /**
   * Check whether the vault is currently locked
   *
   * @example
   * if(!(await vault.isLocked())) {
   *     // Do something if vault is not locked
   * }
   *
   * @returns whether the vault is locked
   */
  isLocked(): Promise<boolean>;

  /**
   * Check whether the vault is currently empty
   *
   * @example
   * if(!(await vault.isEmpty())) {
   *     // Do something if vault is not empty
   * }
   *
   * @returns whether the vault is empty
   */
  isEmpty(): Promise<boolean>;

  /**
   * Check whether the biometrics are locked on the device
   *
   * @example
   * const bioLockedOut = await vault.isLockedOutOfBiometrics();
   *
   * @returns whether biometrics are locked
   */
  isLockedOutOfBiometrics(): Promise<boolean>;

  /**
   * Check whether the vault has any values stored in it.
   *
   * @example
   * const vaultIsInUse = await vault.isInUse();
   *
   * @returns whether the vault has stored values
   */
  isInUse(): Promise<boolean>;

  /**
   * Get the current configuration of the vault
   *
   * @example
   * const config = await vault.getConfig();
   *
   * @returns the configuration
   */
  getConfig(): Promise<PluginConfiguration>;

  /**
   * Check how many remaining failed attempts are left until vault clears
   *
   * @example
   * const remainingAttempts = await vault.remainingAttempts();
   *
   * @returns the number of remaining attempts
   */
  remainingAttempts(): Promise<number>;

  /**
   * Get the username associated with the vault
   *
   * @example
   * const username = await vault.getUsername();
   *
   * @returns the username for the vault
   */
  getUsername(): Promise<string>;

  /**
   * Store a value securely in the token slot. This is equivalent to calling {@link storeValue storeValue("token", token)}.
   *
   * @example
   * vault.storeToken(token);
   *
   * @param token - the value to store in the token slot
   *
   * @throws {@link VaultError} - if vault is locked, or if passcode is enabled but passcode has not been setup
   */
  storeToken(token: any): Promise<void>;

  /**
   * Get any data stored in the token slot. This is equivalent to calling {@link getValue getValue("token")}.
   *
   * @example
   * const token = await vault.getToken();
   *
   * @returns token data
   *
   * @throws {@link VaultError} - if vault is locked
   */
  getToken(): Promise<any>;

  /**
   * Store data securely under the given key.
   *
   * @example
   * vault.storeValue("email", emailToken);
   *
   * @param key - the key to store in the value in
   * @param value - the value to store
   *
   * @throws {@link VaultError} - if vault is locked, or if passcode is enabled but passcode has not been setup
   */
  storeValue(key: string, value: any): Promise<void>;

  /**
   * Removes data under the given key.
   *
   * @example
   * vault.removeValue("email");
   *
   * @param key - the key to remove the value from
   *
   * @throws {@link VaultError} - if vault is locked, or if passcode is enabled but passcode has not been setup
   */
  removeValue(key: string): Promise<void>;

  /**
   * Get a value stored under the given key.
   *
   * @param key - the key where the value is stored
   *
   * @returns the value stored at the key
   *
   * @throws {@link VaultError} - if vault is locked
   */
  getValue(key: string): Promise<any>;

  /**
   * Get all keys with stored values.
   *
   * @example
   * const keys = await vault.getKeys();
   *
   * @returns array with all keys
   *
   * @throws {@link VaultError} - if vault is locked
   */
  getKeys(): Promise<string[]>;

  /**
   * Get the type of biometrics the device supports
   *
   * @example
   * const biometricType = await vault.getBiometricType();
   *
   * @deprecated this method has been deprecated in favor of {@link getAvailableHardware}
   *
   * @returns the type of biometrics the device supports
   */
  getBiometricType(): Promise<BiometricType>;

  /**
   * Gets the types of biometrics the device supports.
   *
   * Please note, this method only shows the biometrics the device is capable of, and does
   * not reflect whether the biometric methods are enrolled or enabled.
   *
   * Traditionally Android has only supported fingerprint biometrics through the SDK. As of
   * Android 10 multiple options are supported. Samsung devices can offer iris and face
   * biometrics that exists outside the scope of the Android SDK. Since there is no official
   * Android SDK support to detect those Samsung features, we attempt to determine their
   * presence based on if the device has the Samsung face or iris biometrics software installed.
   *
   * @example
   * const availableHardware = await vault.getAvailableHardware();
   *
   * @returns the list of biometrics the device supports
   */
  getAvailableHardware(): Promise<SupportedBiometricType[]>;

  /**
   * Enable/Disable Biometric authentication for the vault
   *
   * @example
   * vault.setBiometricsEnabled(true);
   *
   * @param isBiometricsEnabled - whether or not biometrics should be enabled
   *
   * @throws {@link VaultError} - if biometrics is unavailable or the vault is locked
   */
  setBiometricsEnabled(isBiometricsEnabled: boolean): Promise<void>;

  /**
   * Set whether or not the screen will be obscured in app switcher mode
   *
   * @param enabled
   * @param dimBiometrics? - optional boolean for Android to obscure
   * the screen behind biometric prompts via dimming the background instead of the splash screen.
   *
   * @returns void
   */
  setHideScreenOnBackground(enabled: boolean, dimBiometrics?: boolean): Promise<void>;

  /**
   * Check whether or not biometrics is enabled on the vault
   *
   * @example
   * const biometricsEnabled = await vault.isBiometricsEnabled();
   *
   * @returns whether or not biometrics is enabled
   */
  isBiometricsEnabled(): Promise<boolean>;

  /**
   * Check whether or not biometrics is supported by the device
   * and has been configured by the current user of the device
   *
   * @example
   * const biometricsAvailable = await vault.isBiometricsAvailable();
   *
   * @returns whether or not biometrics is available
   */
  isBiometricsAvailable(): Promise<boolean>;

  /**
   * Check whether or not biometrics is supported by the device
   *
   * @example
   * const biometricsSupported = await vault.isBiometricsSupported();
   *
   * @returns whether or not biometrics is supported
   */
  isBiometricsSupported(): Promise<boolean>;

  /**
   * Check whether or not a passcode needs to be set for the vault using {@link setPasscode}
   *
   * @example
   * const passcodeSetupNeeded = await vault.isPasscodeSetupNeeded();
   *
   * @returns whether or not the passcode needs to be set
   */
  isPasscodeSetupNeeded(): Promise<boolean>;

  /**
   * Set or Change the passcode for the vault
   *
   * @example
   * vault.setPasscode();
   *
   * @param passcode - The passcode to set. If left null a native dialog will prompt the user to enter it.
   *
   * @throws {@link VaultError} - if the vault is locked, passcode is disabled, user canceled pin prompt, or pin was mismatched
   */
  setPasscode(passcode?: string): Promise<void>;

  /**
   * Check if passcode authentication is enabled for the vault
   *
   * @example
   * const passcodeEnabled = await vault.isPasscodeEnabled();
   *
   * @returns whether or not the passcode is enabled
   */
  isPasscodeEnabled(): Promise<boolean>;

  /**
   * Enable/Disable passcode authentication for the vault
   *
   * @example
   * vault.setPasscodeEnabled(true);
   *
   * @param isPasscodeEnabled - whether or not passcode should be enabled
   *
   * @throws {@link VaultError} - if the vault is locked
   */
  setPasscodeEnabled(isPasscodeEnabled: boolean): Promise<void>;

  /**
   * Check if {@link AuthMode.SecureStorage} is enabled for the vault
   *
   * @example
   * const secureStorageModeEnabled = await vault.isSecureStorageModeEnabled();
   *
   * @returns whether or not the secure storage mode is enabled
   */
  isSecureStorageModeEnabled(): Promise<boolean>;

  /**
   * Enable/Disable secure storage mode for the vault. Setting {@link AuthMode.SecureStorage} automatically disables passcode
   * and biometric authentication and allows for session values to be stored persistently and securely at rest using the keychain but allowing the user
   * to access the data without authenticating as long as the device is unlocked.
   *
   * @example
   * vault.setSecureStorageModeEnabled(true);
   *
   * @param isSecureStorageModeEnabled - whether or not secure storage mode should be enabled
   *
   * @throws {@link VaultError} - if the vault is locked
   */
  setSecureStorageModeEnabled(isSecureStorageModeEnabled: boolean): Promise<void>;

  /**
   * Unlock the vault using either passcode or biometrics
   *
   * @example
   * vault.unlock();
   *
   * @param usingPasscode - whether or not to use passcode to unlock the vault
   * @param passcode - The passcode to use. If left null a native dialog will prompt the user to enter it.
   *
   * @throws {@link VaultError} - if the vault is locked, the unlock type wasn't enabled, user canceled pin prompt, or pin was mismatched
   */
  unlock(usingPasscode?: boolean, passcode?: string): Promise<void>;
}

/**
 * The lock event passed to the {@link onVaultLocked} handler.
 * @ignore
 */
export interface LockEvent {
  /**
   * If the data was persisted to the device before lock
   */
  saved: boolean;
  /**
   * If the lock was due to a background timeout
   */
  timeout: boolean;
}

/**
 * The main class which you can extend in order to implement vault usage.
 *  @typeparam `T` must extend {@link DefaultSession}
 *
 * @usage
 * ```typescript
 * interface MyCustomSession extends DefaultSession {
 *   // username & token are inherited
 *   email: string;
 *   age: number;
 *   nicknames: string[];
 * }
 *
 * export class IdentityService extends IonicIdentityVaultUser<MyCustomSession> {
 *
 * constructor(private http: HttpClient, private router: Router, platform: Platform) {
 *   super(platform, {
 *     authMode: AuthMode.BiometricAndPasscode,
 *     restoreSessionOnReady: false,
 *     unlockOnReady: false, // set true to auto prompt the user to unlock when vault is ready
 *     unlockOnAccess: true,
 *     lockAfter: 5000, // lock after 5 seconds in the background
 *     hideScreenOnBackground: true
 *   });
 *
 *   onVaultUnlocked(config: VaultConfig) {
 *     //Route to my home page
 *   }
 *
 *   onVaultLocked(event: LockEvent) {
 *     //Route to my login page
 *   }
 *
 * }
 * ```
 * @ignore
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export interface IdentityVaultUser<T extends {} = DefaultSession> {
  /**
   * Get raw access to the underlying vault api
   */
  getVault(): Promise<IdentityVault>;

  /**
   * The stored session data
   */
  getSession(): Promise<T | undefined>;

  /**
   * Get the session from memory (without checking the vault for it)
   */
  getSession(): Promise<T | undefined>;

  /**
   * Called when the vault has been locked
   * @param event - The data about the lock event {@link LockEvent}
   */
  onVaultLocked(event: LockEvent): any;

  /**
   * Called when the vault has been unlocked
   *
   * @param config - The current configuration {@link VaultConfig} of the vault
   */
  onVaultUnlocked(state: VaultConfig): any;

  /**
   * Called when there has been a configuration change in the vault
   *
   * @param config - The updated configuration {@link VaultConfig} of the vault
   */
  onConfigChange(state: VaultConfig): any;

  /**
   * Called when the vault is ready
   *
   * @param config - The current configuration {@link VaultConfig} of the vault
   *
   * @returns - A promise that resolves when the vault is successfully configured and available.
   */
  onVaultReady(state: VaultConfig): any;

  /**
   * Called when there is an error during vault setup
   *
   * @param error - The {@link VaultError} that occurred.
   *
   */
  onSetupError(error: VaultError): any;

  /**
   * Called when the session object is restored.
   *
   * @param session - The {@link DefaultSession} or user defined session that occurred.
   *
   */
  onSessionRestored(session: T): any;

  /**
   * Called when the session fails to auto restore
   *
   * @param err - The {@link VaultError} or that occurred.
   *
   */
  onSessionRestoreError(err: VaultError): any;

  /**
   * Called when the automatically unlocking the vault after it is ready fails.
   *
   * @param err - The {@link VaultError} or that occurred.
   *
   */
  onUnlockOnReadyError(err: VaultError): any;

  /**
   * Called when attempting passcode unlock to allow for user defined passcode prompts.
   *
   * @param isPasscodeSetRequest - Whether or not this is a request to set the passcode.
   *
   * @returns - a string to use as the passcode of undefined to use native prompts
   *
   */
  onPasscodeRequest(isPasscodeSetRequest: boolean): Promise<string | void>;

  /**
   * Promise ensuring the user vault is ready to be accessed or denoting an error in setup;
   *
   * @returns - A promise that resolves when the vault is succesfully configured and available.
   */
  ready(): Promise<void>;

  /**
   * Get the type of biometrics the device supports
   *
   * @returns the type of biometrics the device supports
   */
  getBiometricType(): Promise<BiometricType>;

  /**
   * Set the {@link AuthMode} for the vault. The vault must be unlocked or this will throw an error.
   *
   * @param authMode - The {@link AuthMode} to use.
   *
   * @throws {@link VaultError} - If the vault is locked or the mode is unavailale due to device hardware
   */
  setAuthMode(authMode: AuthMode): Promise<void>;

  /**
   * Get the {@link AuthMode} for the vault.
   */
  getAuthMode(): Promise<AuthMode>;

  /**
   * Returns the underlying Plugin Implementation. This can be overriden in the sub class
   * service to allow for a customer browser implementation. Note that when overriding this
   * with a browser implementation you should use the storeValue/getValue functions with the key
   * `session` to store & retrieve the session as described or by {@link DefaultSession} or the interface
   * that extends it.
   *
   * @usage
   * ```typescript
   * getPlugin(): IonicNativeAuthPlugin {
   *   if (this.platform.is('cordova')) {
   *     return super.getPlugin();
   *   }
   *   // MyCustomerBrowserImplementation must implement the IonicNativeAuthPlugin interface
   *   // make sure getValue('session') & storeValue('session') store & retrieve the session.
   *   return MyCustomBrowserImplementation();
   * }
   * ```
   */
  getPlugin(): IonicNativeAuthPlugin;

  /**
   * Lock the user out without clearing their secure session
   * information from the vault
   */
  lockOut(): Promise<void>;

  /**
   * Unlock the user's vault using the AuthMode configured for the vault
   * or the override passed in to the call.
   * This will call the {@link onPasscodeRequest} handler
   * if the specified {@link AuthMode} allows for it to allow for user defined passcode prompt
   * You can get more fine grain contol using the {@link IdentityVault} directly
   * by calling {@link getVault}
   */
  unlock(authMode?: AuthMode): Promise<void>;

  /**
   * Log the user out entirely, and forget any stored
   * authentication tokens
   */
  logout(): Promise<void>;

  /**
   * Check if there are any saved sessions in the vault
   */
  hasStoredSession(): Promise<boolean>;

  /**
   * Restore the session from the vault
   */
  restoreSession(): Promise<T | undefined>;

  /**
   * Store the session to the vault
   * @param session - the session to store
   */
  saveSession(session: T): Promise<void>;

  /**
   * Login a new session for the user. This method will clear the vault & any stored PIN for
   * previously stored sessions.
   *
   * @param session - the session to store
   * @param authMode? - optionally specify the {@link AuthMode} to  use for the new session.
   * Defaults to the current configuration for the vault if undefined.
   *
   */
  login(session: T, authMode?: AuthMode): Promise<void>;

  /**
   * Enable/Disable Biometric authentication
   *
   * @param isBiometricsEnabled - whether or not biometrics should be enabled
   *
   * @throws {@link VaultError} - if biometrics is unavailable or the vault is locked
   */
  setBiometricsEnabled(isBiometricsEnabled: boolean): Promise<void>;

  /**
   * Set whether or not the screen will be obscured in app switcher mode
   *
   * @param enabled
   * @param dimBiometrics? - optional parameter for Android to obscure the screen behind biometric prompts via dimming the background instead of the splash screen.
   *
   * @returns void
   */
  setHideScreenOnBackground(enabled: boolean, dimBiometrics?: boolean): Promise<void>;

  /**
   * Enable/Disable passcode authentication. This will cause {@link setPasscode}
   * to fire if passcode setup is required causing
   * the {@link onPasscodeRequest} handler to fire
   * to allow for user defined passcode prompt.
   * You can get more fine grain contol using the {@link IdentityVault} directly
   * by calling {@link getVault}
   *
   * @param isPasscodeEnabled - whether or not passcode should be enabled
   *
   * @throws {@link VaultError} - if the vault is locked
   */
  setPasscodeEnabled(isPasscodeEnabled: boolean): Promise<void>;

  /**
   * Set or change the passcode for the user
   * This will call the {@link onPasscodeRequest} handler
   * to allow for user defined passcode prompt.
   * You can get more fine grain contol using the {@link IdentityVault} directly
   * by calling {@link getVault}
   *
   * @throws {@link VaultError} - if the vault is locked, passcode is disabled, user canceled pin prompt, or pin was mismatched
   */
  setPasscode(): Promise<void>;

  /**
   * Check whether or not biometrics is enabled on the vault
   *
   * @returns whether or not biometrics is enabled
   */
  isBiometricsEnabled(): Promise<boolean>;

  /**
   * Check whether or not biometrics is supported by the device
   * and has been configured by the current user of the device
   *
   * @returns whether or not biometrics is available
   */
  isBiometricsAvailable(): Promise<boolean>;

  /**
   * Check whether or not biometrics is supported by the device
   *
   * @returns whether or not biometrics is supported
   */
  isBiometricsSupported(): Promise<boolean>;

  /**
   * Check if passcode authentication is enabled for the vault
   *
   * @returns whether or not the passcode is enabled
   */
  isPasscodeEnabled(): Promise<boolean>;

  /**
   * Check if {@link AuthMode.SecureStorage} is enabled for the vault
   *
   * @returns whether or not the secure storage mode is enabled
   */
  isSecureStorageModeEnabled(): Promise<boolean>;
}

/**
 * The native plugin
 * @ignore
 */
export interface IonicNativeAuthPlugin {
  getVault(config: PluginOptions): IdentityVault;
}
