export class TaskQueue {
  private static instance: TaskQueue;
  private queue: (() => Promise<any>)[];
  private isProcessing: boolean;

  // Private constructor to prevent creating new instances of the class
  private constructor() {
    this.queue = [];
    this.isProcessing = false;
  }

  // Public static method to get the singleton instance of TaskQueue
  public static getInstance(): TaskQueue {
    if (!TaskQueue.instance) {
      TaskQueue.instance = new TaskQueue();
    }
    return TaskQueue.instance;
  }

  // Add a new task to the queue and process it if not already processing
  async addTask<T>(task: () => Promise<T>): Promise<T> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<T>(async (resolve, reject) => {
      const wrappedTask = async () => {
        try {
          const result = await task();
          resolve(result);
        } catch (error) {
          reject(error);
        }
      };

      this.queue.push(wrappedTask);

      if (!this.isProcessing) {
        await this.processTasks();
      }
    });
  }

  // Process the tasks in the queue one by one
  private async processTasks(): Promise<void> {
    this.isProcessing = true;

    while (this.queue.length > 0) {
      const task = this.queue.shift();
      if (task) {
        await task();
      }
    }

    this.isProcessing = false;
  }
}
